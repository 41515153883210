<template>
  <div class="publish">
    <div class="publishForm">
      <el-form
        ref="form"
        label-width="94px"
        :model="form"
        :rules="rules"
      >
        <p class="title">
          基本信息
        </p>
        <el-form-item
          label="名称"
          prop="name"
        >
          <el-input
            v-model="form.name"
            placeholder="请输入名称"
          />
        </el-form-item>
        <el-form-item
          label="分类"
          prop="category"
        >
          <el-select
            v-model="form.category" 
            placeholder="请选择分类"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="图片"
          prop="url"
        >
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :limit="1"
            :on-change="getFile"
            :auto-upload="false"
          >
            <img
              v-if="form.url"
              :src="form.url"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            />
          </el-upload>
        </el-form-item>
        <el-form-item
          label="价格"
          prop="price"
        >
          <el-input
            v-model="form.price"
            placeholder="请输入价格"
          />
        </el-form-item>
        <el-form-item
          label="产品介绍"
          prop="desc"
        >
          <el-input
            v-model="form.desc"
            type="textarea"
            :rows="3"
            placeholder="请输入产品介绍"
          />
        </el-form-item>
        <p class="title">
          联系人
        </p>
        <el-form-item
          label="联系人"
          prop="contact"
        > 
          <el-input
            v-model="form.contact"
            placeholder="请输入联系人"
          />
        </el-form-item>
        <el-form-item
          label="联系人电话"
          prop="phone"
        > 
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item
          label="微信/QQ"
          prop="contactManner"
        > 
          <el-input
            v-model="form.contactManner"
            placeholder="请输入微信/QQ"
          />
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        class="btn"
        @click="submit"
      >
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
    export default {
        data(){
            return{
                options:[{
                value: 0,
                label: '数据产品'
                }, {
                value: 1,
                label: '数据应用'
                }, {
                value: 2,
                label: '数据运营'
                }],
                form:{
                    url:"",
                    name:'',
                    price:'',
                    contact:'',
                    phone:'',
                    category:'',
                    desc:''
                },
                rules:{
                    url:{required:true,message:"请上传图片",trigger:'change'},
                    name:{required:true,message:"请输入名称",trigger:'blur'},
                    contact:{required:true,message:"请输入联系人",trigger:'blur'},
                    phone:[{required:true,message:"请输入联系人电话",trigger:'blur'},{ pattern: /^1[345789]\d{9}$/, message: '请输入有效的手机号', trigger: 'blur' }],
                    category:{required:true,message:"请选择类别",trigger:'change'},
                    desc:{required:true,message:"请输入详情",trigger:'blur'},
                    price:{required:true,message:"请输入价格",trigger:'blur'},
                    contactManner:{required:true,message:"请输入微信/QQ",trigger:'blur'},
                }
            }
        },
        methods:{
            submit(){
                if(localStorage.loginName){
                    this.$refs.form.validate((valid)=>{
                        if(valid){
                            this.$message.success('提交成功')
                            this.$router.push('/')
                        }
                    })
                }
                else{
                    this.$message.warning('请登录账号')
                    this.$router.push('/')
                }
            },
           handleAvatarSuccess(res, file) {
      this.form.url = URL.createObjectURL(file.raw);
},
getFile(file) {
  // if(file.success){
  console.log(file);
  const isJPG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  const isLt5M = file.raw.size / 1024 / 1024 < 5;
  if (!isJPG && !isPNG) {
    this.$message.error("上传图片只能是JPG或者PNG格式!");
  }
  if (!isLt5M) {
    this.$message.error("上传图片大小不能超过 5MB!");
  }
  if ((isJPG || isPNG) && isLt5M) {
    this.getBase64(file.raw).then((res) => {
      console.log(res, "helloword");
      this.form.url = res;  
      this.$refs.form.validateField('url')
      //这里拿到base64的文件流，处理你自己的业务逻辑
    });
  }
},
  getBase64(file) {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      let imgResult = "";
      reader.readAsDataURL(file);
      reader.onload = function () {
        imgResult = reader.result;
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.onloadend = function () {
        resolve(imgResult);
      };
    });
  },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
        }
    }
</script>

<style lang="scss" scoped>
.publish{
display: flex;
justify-content: center;
align-items: center;
padding-top: 30px;
}
.publishForm{
    width: 40vw;
}
.title{
    margin:20px 0 30px;

}
.btn{
    width: 50%;
    display: flex;
    justify-content: center;
    margin:0 auto !important;;
}

 .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon{
      border: 1px dashed #DCDFE6!important;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>